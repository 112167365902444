import * as React from 'react';
import styled from 'styled-components';
// import {useStaticQuery, graphql} from 'gatsby';
// import {GatsbyImage, getImage} from 'gatsby-plugin-image';
// import Gallery from '@browniebroke/gatsby-image-gallery';

import '@fontsource/shadows-into-light';
import '@fontsource/sacramento';
import 'fontsource-open-sans/300.css';
import 'normalize.css';
import Page from '../components/Page';
import Box from '../components/Box';
import Title from '../components/Title';
import ContentWrapper from '../components/ContentWrapper';

// styles
const TitleWrapper = styled(Box).attrs({
  mt: '65px',
})``;

const SubTitle = styled(Box).attrs({
  mt: '40px',
  mb: '25px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
  font-weight: bold;
`;
const Content = styled(Box).attrs({
  mb: '30px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
`;

const Anchor = styled.a`
  color: #6d7770;
  margin-top: 5px;
  word-wrap: break-word;
  :hover {
    color: black;
  }
`;
// const useGallery = () => {
//   const data = useStaticQuery(graphql`
//     query GalleryImages {
//       images: allS3Object(filter: {Key: {glob: "content/gallery/*"}}) {
//         nodes {
//           Key
//           localFile {
//             childImageSharp {
//               thumb: gatsbyImageData(width: 270, height: 270, placeholder: BLURRED)
//               full: gatsbyImageData(layout: FULL_WIDTH)
//             }
//           }
//         }
//       }
//     }
//   `);
//   return data.images.nodes.map(node => node.localFile.childImageSharp);
// };

const RelaxPage = () => {
  return (
    <Page>
      <ContentWrapper>
        <Box m={'0 auto'} maxWidth={'1000px'}>
          <Title text={'Spas'} />
          <SubTitle>Ritz Carlton Bachelor Gulch Spa</SubTitle>
          <Content>
            Unwind and cleanse in the tranquility of the Spa at the Ritz Carlton!
            <br />
            For Reservations or information call 970-343-1106 or visit{' '}
            <Anchor href={'https://www.ritzcarlton.com/en/hotels/colorado/bachelor-gulch/spa'}>
              https://www.ritzcarlton.com/en/hotels/colorado/bachelor-gulch/spa
            </Anchor>
          </Content>
          <SubTitle>Exhale Spa</SubTitle>
          <Content>
            The Hyatt in Beaver Creek is home to the Exhale Spa. Here you can book an array of Spa services including
            Salon services.
            <br />
            For more information call 970-748-7500 or visit{' '}
            <Anchor href={'https://www.hyatt.com/en-US/spas/Allegria-Spa/home'}>
              https://www.hyatt.com/en-US/spas/Allegria-Spa/home
            </Anchor>
          </Content>
          <SubTitle>Spa Anjali</SubTitle>
          <Content>
            Spa Anjali at the Westin in Avon offers a variety of treatments as well as Salon Services.
            <br />
            For Reservations call 970-790-3020 or visit{' '}
            <Anchor href={'https://www.spaanjali.com/'}>https://www.spaanjali.com/</Anchor>
          </Content>
          <TitleWrapper>
            <Title text={'Nails'} />
          </TitleWrapper>

          <Content>
            The best Salon for a manicure or pedicure locally is Natural Nails in Edwards.{' '}
            <Anchor href={'https://naturalnailsspa-nailsalon.business.site/'}>
              https://naturalnailsspa-nailsalon.business.site/
            </Anchor>
          </Content>
          <TitleWrapper>
            <Title text={'Hair'} />
          </TitleWrapper>
          <Content>
            The Hyatt in Beaver Creek is home to the Exhale Spa. Hair and other salon services can be booked here.
            <br />
            For more information call 970-748-7500 or visit{' '}
            <Anchor href={'https://www.hyatt.com/en-US/spas/Allegria-Spa/home'}>
              https://www.hyatt.com/en-US/spas/Allegria-Spa/home
            </Anchor>
          </Content>

          <Content></Content>
        </Box>
      </ContentWrapper>
    </Page>
  );
};

export default RelaxPage;
